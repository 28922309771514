export default (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="17"
    viewBox="0 0 17 17"
  >
    <g fill="none" fillRule="evenodd">
      <path d="M-13-14h44v44h-44z" />
      <path
        className="svg-fill"
        fill="#CFCFCF"
        d="M11.806 10.389h-.747l-.264-.255a6.112 6.112 0 0 0 1.483-3.995 6.139 6.139 0 1 0-6.14 6.139c1.521 0 2.92-.557 3.996-1.483l.255.264v.747l4.722 4.712 1.407-1.407-4.712-4.722zm-5.667 0a4.244 4.244 0 0 1-4.25-4.25 4.244 4.244 0 0 1 4.25-4.25 4.244 4.244 0 0 1 4.25 4.25 4.244 4.244 0 0 1-4.25 4.25z"
      />
    </g>
  </svg>
)
