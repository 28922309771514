export default (
  <svg
    width="25"
    height="25"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.235 0V11.5097H8.75219V0H11.235ZM8.34451 13.5465V2.03687H5.862V13.5465H8.34451ZM2.48266 2.57023V14.0803H0V2.57023H2.48266ZM40 21.6685C40 11.5443 32.1242 3.33698 22.409 3.33698C20.5873 3.33698 18.8303 3.62554 17.1778 4.16111C17.4604 5.22492 17.6114 6.3457 17.6114 7.50323C17.6114 14.4061 12.2415 20.002 5.6175 20.002C5.37405 20.002 5.13229 19.9944 4.89162 19.9791C4.84284 20.5354 4.81791 21.0989 4.81791 21.6685C4.81791 31.7927 12.6937 40 22.409 40C32.1242 40 40 31.7927 40 21.6685ZM5.45432 15.0065V3.49662H2.97166V15.0065H5.45432ZM21.5261 18.621V16.801H14.9601V27H17.3401V23.031H20.8261V21.204H17.3401V18.621H21.5261ZM22.1091 16.801L25.2661 21.757L21.9831 27H24.1951C24.3351 27 24.4518 26.9627 24.5451 26.888C24.6384 26.8087 24.7131 26.72 24.7691 26.622L26.6731 23.318C26.7198 23.2433 26.7618 23.1547 26.7991 23.052L28.8431 26.622C28.8991 26.7247 28.9738 26.8133 29.0671 26.888C29.1651 26.9627 29.2911 27 29.4451 27H31.8111L28.5421 21.652L31.7411 16.801H29.4731C29.2724 16.801 29.1138 16.899 28.9971 17.095L27.2191 20.147C27.1818 20.2077 27.1468 20.266 27.1141 20.322C27.0861 20.378 27.0604 20.4363 27.0371 20.497L25.0071 17.06C24.9511 16.9667 24.8858 16.9013 24.8111 16.864C24.7411 16.822 24.6291 16.801 24.4751 16.801H22.1091Z"
    />
  </svg>
)
